<template>
  <DetailBox
      :info="detailInfo"
      :title="详情"
      :show-box="detailBox.showDetail"
      :show-button="detailBox.showButton"
      :img-button-visible="imgButtonVisible"
      :judge-selected="judgeSelected"
      :history-button-visible="historyButtonVisible"
      @on-click-button="updateSelectedEarthquake(detailBox.quickEarthquakeId)"
      @on-close="detailBox.showDetail = false"
  />
  <div class="buttonClass">
    <el-popover
        ref="controlVisible"
        v-model:visible="layerControl.visible"
        placement="top"
        trigger="click"
        width="170px"
    >
      <template #reference>
        <el-button style="margin: 5px" :size="inputSize" type="primary" plain>
          地震频发
        </el-button>
      </template>
      <p>请选择频发省份？</p>
      <div style="display: flex">
        <el-button
            style="margin: 5px"
            :size="inputSize"
            type="primary"
            plain
            @click="openNewWindow(1)"
        >
          四川
        </el-button>
        <el-button
            style="margin: 5px"
            :size="inputSize"
            type="primary"
            plain
            @click="openNewWindow(3)"
        >
          新疆
        </el-button>
      </div>
    </el-popover>
    <el-button
        type="primary"
        plain
        :size="inputSize"
        style="margin: 5px"
        @click="openNewWindow(2)"
    >
      全国
    </el-button>
    <el-button
        type="primary"
        plain
        :size="inputSize"
        style="margin: 5px"
        icon="el-icon-office-building"
        @click="cameraTo(100.01959853057154, 25.626928636003633, 565)"
    >
      三维重建
    </el-button>

    <el-button
        type="primary"
        plain
        :size="inputSize"
        style="margin: 5px"
        @click="openNewWindow(4)"
    >
      救援力量
    </el-button>


    <el-button
        type="primary"
        plain
        :size="inputSize"
        style="margin: 5px"
        icon="el-icon-plus"
        @click="
        $refs.predict.predictVisible = true;
        closeIndex('predict');
      "
    >
      灾区识别
    </el-button>
    <el-button
        type="primary"
        plain
        :size="inputSize"
        style="margin: 5px"
        icon="el-icon-map-location"
        @click="
        cameraTo(
          selectedEarthquakeInfo.longitude,
          selectedEarthquakeInfo.latitude,
          100000
        );
        closeIndex('');
        imgDisplayVisible();
      "
    >
      直达震区
    </el-button>
    <el-popover
        v-model:visible="layerVisible"
        trigger="manual"
        width="300px"
        placement="top"
    >
      <template #reference>
        <el-button
            style="margin: 5px"
            icon="el-icon-view"
            :size="inputSize"
            type="primary"
            plain
            @click="
            layerVisible = !layerVisible;
            closeIndex('');
          "
        >
          图层控制
        </el-button>
      </template>
      <el-row align="middle">
        选择图层：
        <el-select
            v-model="imageryConfig.mapStyle"
            style="width: 200px"
            :size="inputSize"
            placeholder="请选择"
        >
          <el-option
              v-for="item in imageryConfig.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-row>
      <hr />
      <el-row type="flex" justify="space-between">
        <el-checkbox v-model="layerControl.showIntensity" label="烈度图" />
        <el-checkbox v-model="layerControl.showEpicenter" label="震源" />
        <el-checkbox v-model="layerControl.showHospital" label="显示医院" />
      </el-row>
      <el-row type="flex" justify="space-between">
        <div>
          <el-tooltip placement="top">
            <template #content> 默认的物资总量为1000个 </template>
            <i class="el-icon-info"></i>
          </el-tooltip>
          <el-checkbox
              v-model="layerControl.showFireCenter"
              label="救援物资分配"
          />
        </div>
        <div>
          <el-tooltip placement="top">
            <template #content> 地图上面的边界线 </template>
            <i class="el-icon-info"></i>
          </el-tooltip>
          <el-checkbox v-model="linedataVisible"> 边界线 </el-checkbox>
        </div>
      </el-row>
    </el-popover>
    <el-button
        type="primary"
        plain
        :size="inputSize"
        style="margin: 5px"
        icon="el-icon-plus"
        @click="
        $refs.add.addEarthquakeVisible = true;
        closeIndex('add');
      "
    >

      添加地震
    </el-button>
    <!-- <el-button type="primary" plain :size="inputSize" style="margin: 5px" icon="el-icon-picture"
      @click="$refs.SeismicZones.SeismicZonesVisible=true;closeIndex('SeismicZones')">
      地震带图
    </el-button> -->

    <!--      模型微调参数(100.01778853057153,25.625528636003633)-->
    <!--      ENU:25.625528636003633,100.01778853057153-->
    <el-button
        type="primary"
        plain
        :size="inputSize"
        icon="el-icon-search"
        style="margin: 5px"
        @click="
        $refs.select.earthquakeSelectVisible = true;
        closeIndex('select');
      "
    >
      地震列表
    </el-button>


    <el-button
        type="primary"
        plain
        :size="inputSize"
        style="margin: 5px"
        icon="el-icon-document"
        @click="
        $refs.estimate.getEarthquakeSituation();
        closeIndex('estimate');
      "
    >
      地震评估
    </el-button>
    <el-popover
        v-model:visible="visibleRoad"
        trigger="manual"
        placement="top"
        width="400px"
    >
      <template #reference>
        <el-button
            style="margin: 5px"
            icon="el-icon-position"
            :size="inputSize"
            type="primary"
            plain
            @click="
            visibleRoad = !visibleRoad;
            closeIndex('');
          "
        >
          路径规划
        </el-button>
      </template>
      <el-row type="flex" justify="space-between">
        <el-radio
            v-model="radioRoad"
            :label="1"
            style="width: 100px"
            :size="inputSize"
            border
        >
          驾车
        </el-radio>
        <el-radio
            v-model="radioRoad"
            :label="2"
            style="width: 100px"
            :size="inputSize"
            border
        >
          步行
        </el-radio>
        <el-radio
            v-model="radioRoad"
            :label="3"
            style="width: 100px"
            :size="inputSize"
            border
        >
          电动车
        </el-radio>
      </el-row>
      <el-row type="flex" justify="space-between" style="margin-top: 10px">
        <el-button
            type="primary"
            style="width: 100px"
            plain
            :size="inputSize"
            @click="selectPositionRoad()"
        >
          选取救援点
        </el-button>
        <el-button
            type="primary"
            style="width: 100px"
            plain
            :size="inputSize"
            @click="getPositionRoad()"
        >
          开始规划
        </el-button>
        <el-button
            type="danger"
            plain
            style="width: 100px"
            :size="inputSize"
            @click="stopPositionRoad()"
        >
          结束规划
        </el-button>
      </el-row>
    </el-popover>

    <el-popover :width="670" placement="top" trigger="hover">
      <template #reference>
        <el-button
            style="margin: 5px"
            icon="el-icon-suitcase"
            :size="inputSize"
            type="primary"
            plain
            @click="
            suppliesVisible = true;
            closeIndex('');
          "
        >
          物资分配
        </el-button>
      </template>
      <div>
        设置物资分配总量:
        <el-input-number
            v-model="DistributionSum"
            :step="100"
            :size="inputSize"
            style="margin-top: 10px"
        />
        设置物资分配地区数量:
        <el-input-number
            v-model="DistrictSum"
            :step="5"
            :size="inputSize"
            style="margin-top: 10px"
            :min="5"
            :max="30"
        />
        <el-button
            type="primary"
            plain
            icon="el-icon-success"
            :size="inputSize"
            style="margin-top: 10px; margin-left: 20px"
            @click="
            reGetCalculateWeight();
            suppliesVisible = false;
          "
        >
          计算
        </el-button>
      </div>
    </el-popover>
  </div>
  <el-row class="toolbar">
    <EarthquakeSelect
        ref="select"
        :selected-earthquake-id="selectedEarthquakeInfo.earthquakeId"
        @change-select="updateSelectedEarthquake"
        @update-list="updateEarthquakePositionInfoList"
        @jump-to="cameraTo"
    />
    <EstimateEarthquake ref="estimate" :earthquake="selectedEarthquakeInfo" />
    <Predict ref="predict" />
    <AddEarthquake ref="add" />
    <ImgDisplay ref="img" />
    <SeismicZones ref="SeismicZones" />
  </el-row>
  <!--    cesium代码-->
  <el-row
      ref="viewerContainer"
      v-loading="cesiumLoading"
      class="viewer"
      element-loading-text="初次加载可能稍慢，请耐心等待"
  >
    <vc-viewer
        ref="vcViewer"
        fill="green"
        :animation="viewerConfig.animation"
        :base-layer-picker="viewerConfig.baseLayerPicker"
        :timeline="viewerConfig.timeline"
        :fullscreen-button="viewerConfig.fullscreenButton"
        :fullscreen-element="viewerConfig.fullscreenElement"
        :info-box="viewerConfig.infoBox"
        :selection-indicator="false"
        :show-credit="viewerConfig.showCredit"
        @ready="onViewerReady"
    >
      <!--      加载三维重建模型-->
      <!--      https://www.file.zouran.top/-->
      <vc-primitive-tileset
          ref="primitive"
          url="https://file.zouran.top/smart3d_las/tileset.json"
          :show="layerControl.showModel"
          @ready-promise="onReadyPromise"
          @click="onClicked"
      />

      <!--      data="https://geo.datav.aliyun.com/areas_v3/bound/geojson?code=100000_full"-->
      <!-- 边界线 -->
      <vc-datasource-geojson
          ref="ds"
          data="./china.json"
          :show="linedataVisible"
          :fill="options.fill"
          :stroke="options.stroke"
      />
      <!-- 测绘工具 -->
      <vc-selection-indicator ref="selectionIndicator" @pick-evt="pickEvt" />
      <vc-measurements
          ref="measurementsRef"
          position="bottom-left"
          :main-fab-opts="measurementFabOptions"
          @draw-evt="drawEvt"
          @active-evt="activeEvt"
          @editor-evt="editorEvt"
          @mouse-evt="mouseEvt"
      />
      <!--      地图渲染震源 -->
      <vc-datasource-custom name="epicenter" :show="layerControl.showEpicenter">
        <vc-entity
            v-for="(item, index) in earthquakePositionInfoList"
            :id="'epicenter_' + item.earthquakeId.toString() + '_' + uuid()"
            :key="index"
            :position="[item.longitude, item.latitude, 0]"
            description="epicenter"
        >
          <!-- <vc-graphics-point ></vc-graphics-point> -->
          <vc-graphics-point
              :color="pointcolor(item)"
              :pixel-size="1.5 * item.magnitude"
          />
        </vc-entity>
      </vc-datasource-custom>
      <!-- 烈度图 -->
      <vc-datasource-custom name="intensity" :show="layerControl.showIntensity">
        <vc-entity
            v-for="(item, index) in selectedEarthquakeInfo.intensityLineList"
            :id="'intensity_' + index"
            :key="index"
            :position="[
            selectedEarthquakeInfo.longitude,
            selectedEarthquakeInfo.latitude,
            0,
          ]"
            :description="'最外圈烈度:' + item.intensity"
        >
          <!--              :material="[126, (10-item.intensity)*30,180, 125]"-->
          <vc-graphics-ellipse
              :semi-minor-axis="item.longRadius * 1000"
              :semi-major-axis="item.shortRadius * 1000"
              :material="[255, (10 - item.intensity) * 30, 0, 125]"
              :rotation="item.angle"
              :fill="true"
          />
        </vc-entity>
      </vc-datasource-custom>
      <!--      <vc-terrain-provider-tianditu token="fd7029d3dff756b437af91d68aadc6bf"></vc-terrain-provider-tianditu>-->
      <vc-layer-imagery
          :alpha="imageryConfig.alpha"
          :brightness="imageryConfig.brightness"
          :contrast="imageryConfig.contrast"
          :sort-order="10"
      >
        <vc-imagery-provider-tianditu
            ref="provider"
            :map-style="imageryConfig.mapStyle"
            token="de232c2bf878c7a7928afde78e339913"
        />
      </vc-layer-imagery>
      <vc-navigation
          :offset="navigationConfig.offset"
          :other-opts="navigationConfig.otherOpts"
      />
      <vc-ajax-bar />
      <!-- 医院位置 -->
      <vc-datasource-custom
          :show="layerControl.showHospital"
          name="hospitalList"
          :entities="hospitalBillboards"
          @cluster-event="onHospitalClusterEvent"
          @ready="onHospitalReady"
      />
      <!--        hyc：增加椭圆显示位置-->
      <vc-entity
          ref="entity"
          :position="[longTemp, latiTemp]"
          description="您所点击的位置所表示的区域"
      >
        <vc-graphics-ellipse
            :semi-minor-axis="50.0"
            :semi-major-axis="50.0"
            :material="[255, 0, 0, 125]"
        />
        <vc-graphics-label
            text="起点"
            font="20px sans-serif"
            :pixel-offset="[0, 20]"
            fill-color="red"
        />
      </vc-entity>
      <!--hyc:消防队位置-->
      <vc-datasource-custom
          :show="layerControl.showFireCenter"
          name="fireCenterList"
          :entities="fireCenterBillboards"
          @cluster-event="onFireCenterClusterEvent"
          @ready="onFireCenterReady"
          @mouseout="onMouseout"
          @mouseover="onMouseover"
      />
    </vc-viewer>
  </el-row>
</template>

<script>
import {
  getAllNearHospitalPositionByEarthquakeId,
  getHospitalByHospitalId,
  getDetailEarthquakeInfoById,
  initEarthquakePositionInfo,
  getEarthquakeInfoById,
  getNearFireCenterWeightByEarthquakeId,
  getNearestHospitalByPosition,
  restapi,
} from "@/axios/api/CesiumApi";
import DetailBox from "../components/DetailBox";
import EarthquakeSelect from "../components/EarthquakeSelect";
import AddEarthquake from "../components/AddEarthquake";
import EstimateEarthquake from "../components/EstimateEarthquake";
import Predict from "@/components/Predict.vue";
import {
  cartesianToLnglat,
  gcj2wgs,
  lnglatArrToCartesianArr,
  wgs2gcj,
} from "@/js/cesium";
import ImgDisplay from "@/components/ImgDisplay.vue";
import SeismicZones from "@/components/SeismicZones.vue";
export default {
  name: "Cesium",
  components: {
    Predict,
    SeismicZones,
    ImgDisplay,
    DetailBox,
    EarthquakeSelect,
    AddEarthquake,
    EstimateEarthquake,
  },
  data() {
    return {
      inputSize: "mini",
      //详情传值
      detailInfo: {},
      //历史地震控制
      historyButtonVisible: false,
      //物资分配框控制
      suppliesVisible: false,
      //图片按钮控制
      imgButtonVisible: false,
      //边界线控制
      linedataVisible: false,
      //边界线选项
      options: {
        fill: [0, 0, 0, 0.1],
        stroke: "yellow",
      },
      //物资分配
      DistrictSum: 10,
      layerVisible: false,
      activeCalculateWeight: ["1"],
      DistributionSum: 1000,
      cesiumLoading: true,
      fireCenterLoading: false,
      visibleRoad: false,
      radioRoad: 1,
      Search: this.Search,
      Check: this.Check,
      //data
      fireWeight: [],
      hospitalBillboards: [],
      fireCenterBillboards: [],
      hospitalList: [],
      //记录列表地震
      earthquakeInfoList: [],
      //earthquakeInfo
      earthquakePositionInfoList: [
        {
          earthquakeId: 1,
          longitude: 99.87,
          latitude: 25.67,
          magnitude: 6.4,
        },
      ],
      //当前地震
      selectedEarthquakeInfo: {
        earthquakeId: 1474,
        earthquakeName: "云南大理州漾濞县",
        magnitude: 6.4,
        highIntensity: 8.308250979049514,
        longitude: 99.87,
        latitude: 25.67,
        depth: 8.0,
        earthquakeTime: "2021-05-21 21:48:34",
        intensityLineList: [
          {
            longRadius: 4.831947699520125,
            shortRadius: 2.1831727770881724,
            angle: 0.6,
            intensity: 8,
            earthquakeId: "1474",
          },
          {
            longRadius: 27.59874969680684,
            shortRadius: 14.267550354961134,
            angle: 0.6,
            intensity: 7,
            earthquakeId: "1474",
          },
          {
            longRadius: 67.17692145364093,
            shortRadius: 40.692466450766986,
            angle: 0.6,
            intensity: 6,
            earthquakeId: "1474",
          },
        ],
      },
      //detailBox
      detailBox: {
        showDetail: false,
        detailClass: 0,
        detailIndex: 0,
        quickEarthquakeId: 0,
        showButton: true,
      },
      //measure
      measurementFabOptions: {
        modelValue: false,
        direction: "right",
      },
      //viewer
      viewerConfig: {
        animation: false,
        timeline: false,
        baseLayerPicker: false,
        fullscreenButton: false,
        infoBox: false,
        showCredit: false,
        fullscreenElement: document.body,
      },
      //imagery-provider
      imageryConfig: {
        alpha: 1,
        brightness: 1,
        contrast: 1,
        mapStyle: "img_w",
        options: [
          {
            value: "img_c",
            label: "影像地图服务(经纬度)",
          },
          {
            value: "img_w",
            label: "影像地图服务(墨卡托)",
          },
          {
            value: "vec_c",
            label: "矢量地图服务(经纬度)",
          },
          {
            value: "vec_w",
            label: "矢量地图服务(墨卡托)",
          },
          {
            value: "ter_c",
            label: "地形晕渲服务(经纬度)",
          },
          {
            value: "ter_w",
            label: "地形晕渲服务(墨卡托)",
          },
          {
            value: "ibo_c",
            label: "境界(经纬度)",
          },
          {
            value: "ibo_w",
            label: "境界(墨卡托)",
          },
        ],
      },
      //navigation
      navigationConfig: {
        offset: [10, 25],
        otherOpts: {
          offset: [0],
          position: "bottom-right",
        },
      },
      //layerControl
      layerControl: {
        visible: false,
        showIntensity: false, //更改
        showEpicenter: true,
        showHospital: false,
        showFireCenter: false,
      },
      // hyc2
      num: 0,
      SuccessClick: false,
      startLon: 0.0,
      startLat: 0.0,
      startHei: 0.0,
      longTemp: 0.0,
      latiTemp: 0.0,
      heiTemp: 0.0,
      endLon: 0.0,
      endLat: 0.0,
      endHei: 0.0,
    };
  },
  computed: {
    judgeSelected: function () {
      //不可更改为===
      return (
          this.detailBox.quickEarthquakeId !==
          this.selectedEarthquakeInfo.earthquakeId
      );
    },
  },
  watch: {
    timeline(val) {
      this.navigationConfig.otherOpts.offset = val
          ? [0, 30]
          : this.fullscreenButton
              ? [30, 5]
              : [0, 5];
    },
    fullscreenButton(val) {
      if (!this.timeline && !val) {
        this.navigationConfig.otherOpts.offset = [0, 5];
      } else if (!this.timeline && val) {
        this.navigationConfig.otherOpts.offset = [30, 5];
      }
    },
    detailBox: {
      handler(detailbox) {
        this.imgButtonVisible =
            detailbox.quickEarthquakeId === 1474 && detailbox.detailClass === 1;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getEarthquakeList();
  },
  mounted() {
    // this.$refs.vcViewer.createPromise.then(({ viewer }) => {
    //   viewer.scene.globe.depthTestAgainstTerrain = false;
    // });
    // this.$refs.model.createPromise.then(({ Cesium, viewer }) => {
    //   // viewer.zoomTo(viewer.entities)
    // })
  },
  methods: {
    openNewWindow(val) {
      if (val == 1) {
        this.$router.push("/sichuan");
      } else if (val == 2) {
        this.$router.push("/test2");
      } else if (val == 3) {
        this.$router.push("/xinjiang");
      }else if(val==4){
        this.$router.push("/Rescue_forces");
      }

      // window.open("http://127.0.0.1:5173/big-screen-1/", );
    },
    uuid() {
      const s = [];
      const hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = "-";

      this.uuidA = s.join("");
      // console.log(this.uuidA);
      return this.uuidA;
    },
    imgDisplayVisible() {
      this.$refs.img.ImgDisplayVisible =
          this.selectedEarthquakeInfo.earthquakeId === 1474;
    },
    closeIndex(val) {
      this.detailBox.showDetail = false;
      this.$refs.add.addEarthquakeVisible = false;
      this.$refs.estimate.dialogVisible = false;
      this.$refs.select.earthquakeSelectVisible = false;
      this.$refs.SeismicZones.SeismicZonesVisible = false;
      if (val === "predict") this.$refs.predict.addEarthquakeVisible = true;
      if (val === "add") this.$refs.add.addEarthquakeVisible = true;
      if (val === "estimate") this.$refs.estimate.dialogVisible = true;
      if (val === "select") this.$refs.select.earthquakeSelectVisible = true;
      if (val === "SeismicZones")
        this.$refs.SeismicZones.SeismicZonesVisible = true;
      console.log(val);
    },
    pointcolor(item) {
      // console.log(item.magnitude)
      if (4 > item.magnitude) return "#efe8e8";
      else if (5 > item.magnitude) return "#b4f19a";
      else if (6 > item.magnitude) return "#f3a201";
      else return "#ff263c";
    },
    reGetCalculateWeight() {
      this.$message.warning("请耐心等待，后台正在重新进行物资分配~");
      this.fireWeight = [];
      this.fireCenterBillboards = [];
      this.getFireCenters();
    },
    //update earthquake list
    updateEarthquakePositionInfoList(list) {
      this.earthquakePositionInfoList = list;
      for (let i = 0; i < list.length; i++) {
        if (this.selectedEarthquakeInfo.earthquakeId === list[i].earthquakeId)
          return;
      }
      if (list.length === 0) return;
      this.selectedEarthquakeInfo = list[0];
      this.getHospitals();
    },
    //get earthquake list
    getEarthquakeList() {
      let that = this;
      //that.cesiumLoading = false;
      //that.$message.success("正在查询地震数据,请稍后....");
      initEarthquakePositionInfo(5.6).then((response) => {
        that.earthquakePositionInfoList = response;
        that.getHospitals();
        that.cesiumLoading = false;
        // setTimeout(function () {
        //   that.cameraTo(
        //     that.selectedEarthquakeInfo.longitude,
        //     that.selectedEarthquakeInfo.latitude,
        //     100000
        //   );
        // }, 2000);
        //console.log("camera");
        // that.getFireCenters();
      });
    },
    //change selected earthquake by index
    updateSelectedEarthquake(earthquakeId) {
      console.log(earthquakeId);
      const that = this; //防止访问不到外部的this，桥梁作用
      // this.imageryConfig.mapStyle="img_w";
      getEarthquakeInfoById(earthquakeId).then((response) => {
        that.selectedEarthquakeInfo = response;
        that.getHospitals();
        that.detailBox.showDetail = false;
      });
    },
    //change location
    cameraTo(lat, lon, height) {
      this.imageryConfig.mapStyle = "img_w";
      let viewer = this.$refs.vcViewer.getCesiumObject();
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(lat, lon, height),
        orientation: {
          heading: Cesium.Math.toRadians(0.0),
          pitch: Cesium.Math.toRadians(-90.0),
          roll: 0.0,
        },
      });
    },
    //event when mouse pick an entity or a position
    pickEvt(e) {
      if (!e) {
        console.log("!e");
        return;
      }
      this.layerControl.visible = false;
      try {
        // console.log('pickEvt',e)
        if (e._id === "__Vc__Pick__Location__") {
          if (this.num !== 0) {
            let cartographic = Cesium.Cartographic.fromCartesian(
                e._position._value
            );
            this.longTemp = Cesium.Math.toDegrees(cartographic.longitude);
            this.latiTemp = Cesium.Math.toDegrees(cartographic.latitude);
            this.heiTemp = cartographic.height;
            // console.log('经纬度',this.longTemp,this.latiTemp,this.heiTemp)
            this.num = 0;
          } else {
            // this.visibleRoad = false;
          }
        }
        let kind = e.id.split("_")[0];
        let index = parseInt(e.id.split("_")[1]);
        if (kind === "epicenter") {
          const that = this;
          getDetailEarthquakeInfoById(index).then((response) => {
            that.detailInfo = response;
            that.historyButtonVisible = true;
            that.detailBox.showButton = true;
            that.detailBox.showDetail = true;
            that.detailBox.detailClass = 1;
            that.detailBox.detailIndex = index;
            that.detailBox.quickEarthquakeId = index;
          });
        } else if (kind === "hospital") {
          const that = this;
          getHospitalByHospitalId({
            hospitalId: index,
          }).then((response) => {
            console.log(response,9090);
            that.detailInfo = response;
            that.historyButtonVisible = false;
            that.detailBox.showButton = false;
            that.detailBox.showDetail = true;
            that.detailBox.detailClass = 2;
            that.detailBox.detailIndex = index;
            that.detailBox.quickEarthquakeId = index;
          });
        } else if (kind === "fireCenter") {
          console.log(e.id,8989)
          this.historyButtonVisible = false;
          index = parseInt(e.id.split("_")[1]);
          this.detailBox.showButton = false;
          this.detailBox.showDetail = true;
          this.detailBox.detailClass = 3;
          this.detailBox.detailIndex = index;
          this.detailInfo = [
            {
              key: "物资数量",
              value: this.detailBox.detailIndex,
            },
          ];
        } else if (kind === "intensity") {
          index = parseInt(e.id.split("_")[1]);
          this.detailBox.detailIndex = index;
          this.detailInfo = [
            {
              key: "烈度",
              value:
                  this.selectedEarthquakeInfo.intensityLineList[
                      this.detailBox.detailIndex
                      ].intensity + "(选中区域外圈等烈度线)",
            },
            {
              key: "长轴半径",
              value:
                  this.selectedEarthquakeInfo.intensityLineList[
                      this.detailBox.detailIndex
                      ].longRadius + "km",
            },
            {
              key: "短轴半径",
              value:
                  this.selectedEarthquakeInfo.intensityLineList[
                      this.detailBox.detailIndex
                      ].shortRadius + "km",
            },
          ];
          this.historyButtonVisible = false;
          this.detailBox.showButton = false;
          this.detailBox.showDetail = true;
          this.detailBox.detailClass = 4;
        } else {
          this.detailBox.showDetail = false;
        }
      } catch (err) {
        //console.log(err);
        //console.log("error");
        this.detailBox.showDetail = false;
      }
    },
    onViewerReady(cesiumInstance) {
      // hyc
      const { Cesium, viewer } = cesiumInstance;
      var position = viewer.camera.positionCartographic;
      viewer.camera.moveBackward(position.height * 0.5);

      viewer.scene.globe.depthTestAgainstTerrain = false;
      //可以选择flyto方法，飞行更流
    },
    onReadyPromise(tileset) {
      const cartographic = Cesium.Cartographic.fromCartesian(
          tileset.boundingSphere.center
      );
      const surface = Cesium.Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          cartographic.height
      );
      const offset = Cesium.Cartesian3.fromRadians(
          cartographic.longitude - 0.000009,
          cartographic.latitude + 0.000004,
          0
      );
      const translation = Cesium.Cartesian3.subtract(
          offset,
          surface,
          new Cesium.Cartesian3()
      );
      tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
      // viewer.zoomTo(tileset)
    },

    onClicked(e) {
      let cartographic = Cesium.Cartographic.fromCartesian(e.surfacePosition);
      console.log(
          "lng:",
          Cesium.Math.toDegrees(cartographic.longitude),
          ",lat:",
          Cesium.Math.toDegrees(cartographic.latitude)
      );
    },
    getHospitals() {
      // hyc
      let that = this;
      that.hospitalBillboards = [];
      getAllNearHospitalPositionByEarthquakeId({
        earthquakeId: that.selectedEarthquakeInfo.earthquakeId,
      }).then((res) => {
        this.hospitalList = res.data;
        for (let i = 0; i < res.data.length; i++) {
          let hospitalBillboard = {};
          hospitalBillboard.position = {
            lng: res.data[i].lon,
            lat: res.data[i].lat,
          };
          hospitalBillboard.billboard = {
            id: "hospital_" + i,
            image: "https://file.peteralbus.com/assets/cesium/img/Hospital.png",
            scale: 0.1,
          };
          hospitalBillboard.id = "hospital_" + res.data[i].id;
          that.hospitalBillboards.push(hospitalBillboard);
        }
      });
    },
    getFireCenters() {
      // hyc
      this.fireWeight = [];
      this.fireCenterBillboards = [];
      let that = this;
      this.fireCenterLoading = true;
      getNearFireCenterWeightByEarthquakeId(
          this.selectedEarthquakeInfo.earthquakeId,
          this.DistrictSum
      )
          .then((res) => {
            // this.$axios.get("/findFireCenterNearby?earthquakeId="+that.selectedEarthquakeInfo.earthquakeId).then((res) => {
            let sum = 0;
            for (let i = 0; i < res.data.length; i++) {
              if (i >= that.DistrictSum) {
                break;
              }
              let billboard = {};
              billboard.position = {
                lng: res.data[i].fireLon,
                lat: res.data[i].fireLat,
              };
              console.log( billboard.position,666);
              billboard.billboard = {
                image:
                    "https://file.peteralbus.com/assets/cesium/img/fireCenter.png",
                scale: 0.1,
                weight: 1 / (1 - res.data[i].fireCenterWeight),
                pixelOffset: {
                  x: 0,
                  y: -45,
                },
              };
              billboard.id = sum += billboard.billboard.weight;
              that.fireCenterBillboards.push(billboard);
            }
            for (let i = 0; i < that.fireCenterBillboards.length; i++) {
              let fireWeight1 = {};
              fireWeight1.position = that.fireCenterBillboards[i].position;
              that.fireCenterBillboards[i].label = {
                text:
                    "所要分配的物资数量为:" +
                    Math.floor(
                        (that.fireCenterBillboards[i].billboard.weight / sum) *
                        this.DistributionSum
                    ).toString() +
                    "个",
              };
              that.fireCenterBillboards[i].id =
                  "fireCenter_" +
                  Math.floor(
                      (that.fireCenterBillboards[i].billboard.weight / sum) *
                      this.DistributionSum
                  ).toString() +
                  "_" +
                  i;
              // fireWeight1.text="所要分配的物资数量为:"+Math.floor((that.fireCenterBillboards[i].billboard.weight/sum)*this.DistributionSum).toString()+"个";
              // that.fireWeight.push(fireWeight1);
            }
            that.fireCenterLoading = false;
            that.layerControl.visible = false;
            that.layerControl.showFireCenter = true;
            that.linedataVisible = false;
            that.$message.success("物资分配计算完成");
          })
          .catch(() => {
            that.fireCenterLoading = false;
            that.$message.error("物资分配计算失败");
          });
    },
    stopPositionRoad() {
      this.num = 0;
      this.longTemp = 0.0;
      this.latiTemp = 0.0;
      this.$message.error("结束路径规划,如想要开启路径规划功能，请重新选点");
      // this.visibleRoad = !this.visibleRoad;
    },
    selectPositionRoad() {
      this.num++;
      this.$message.success("现在可以开始选取救援点啦~");
    },
    getPositionRoad() {
      console.log(
          "现在点击的坐标经纬度以及高度为:",
          this.longTemp,
          this.latiTemp,
          this.heiTemp,
          "以及现在num的值为",
          this.num
      );
      console.log("我们现在点击的坐标", this.radioRoad);
      let longitude = this.longTemp;
      let latitude = this.latiTemp;
      let height = this.heiTemp;
      let that = this;
      if (longitude !== 0) {
        that.startLon = longitude;
        that.startLat = latitude;
        that.startHei = height;
      } else {
        that.$message.error("您还没有选取点，请点击屏幕进行选点");
        return;
      }
      // this.imageryConfig.mapStyle="vec_c"
      that.$message.success("开始路径规划！");
      that.layerControl.showHospital = true;
      that.linedataVisible = false;
      //起点：经度："+this.startLon +"纬度："+this.startLat+"高度："+this.startHei
      let start = {
        lng: this.startLon,
        lat: this.startLat,
        hei: this.startHei,
      };
      getNearestHospitalByPosition(this.startLon, this.startLat).then((res) => {
        that.endLat = res.data.endLat;
        that.endLon = res.data.endLon;
        let start = {
          longitude: this.startLon,
          latitude: this.startLat,
          height: this.startHei,
        };
        let end = {
          longitude: that.endLon,
          latitude: that.endLat,
          height: that.endHei,
        };
        that.howRes(start, end); //调用this.howRes
      });
    },
    btn1() {
      console.log("111");
    },
    howRes(start, end) {
      if (!start || !end) return;
      let startP = cartesianToLnglat(start, true);
      let endP = cartesianToLnglat(end, true);
      this.searchRoute([startP[0], startP[1]], [endP[0], endP[1]]);
    },
    searchRoute(startP, endP) {
      startP = wgs2gcj(startP);
      endP = wgs2gcj(endP);
      let travelWay;
      if (this.radioRoad === 1) {
        travelWay = "/v3/direction/driving";
      }
      if (this.radioRoad === 2) {
        travelWay = "/v3/direction/walking";
      }
      if (this.radioRoad === 3) {
        travelWay = "/v4/direction/bicycling";
      }
      const params = {
        travelWay: travelWay,
        startP: startP,
        endP: endP,
      };
      restapi(params).then((res) => {
        console.log("路径规划结果：", res.data);
        let steps;
        if (this.radioRoad === 3) steps = res.data.data.paths[0].steps;
        else if (this.radioRoad === 1 || this.radioRoad === 2)
          steps = res.data.route.paths[0].steps;
        // console.log(steps)
        let arr = [];
        for (let i = 0; i < steps.length; i++) {
          let item = steps[i];
          let positionStr = item.polyline;
          let strArr = positionStr.split(";");
          for (let z = 0; z < strArr.length; z++) {
            let item2 = strArr[z];
            let strArr2 = item2.split(",");
            let p = gcj2wgs(strArr2);
            arr.push(p);
          }
        }
        let cartesianArr = lnglatArrToCartesianArr(arr);
        let viewer = this.$refs.vcViewer.getCesiumObject();
        let colorTraffic;
        if (this.radioRoad === 1) {
          colorTraffic = Cesium.Color.RED.withAlpha(0.9);
        } else if (this.radioRoad === 2) {
          colorTraffic = Cesium.Color.BLUE.withAlpha(1);
        } else {
          colorTraffic = Cesium.Color.YELLOW.withAlpha(1);
        }
        let line = viewer.entities.add({
          polyline: {
            positions: cartesianArr,
            clampToGround: true,
            material: colorTraffic,
            width: 5,
          },
        });
        this.moveOnRoute(line);
      });
    },
    moveOnRoute(lineEntity) {
      let viewer = this.$refs.vcViewer.getCesiumObject();
      let carModel = null;
      if (!lineEntity) return;
      let positions = lineEntity.polyline.positions.getValue();
      if (!positions) return;
      let allDis = 0;
      for (let index = 0; index < positions.length - 1; index++) {
        let distance = Cesium.Cartesian3.distance(
            positions[index],
            positions[index + 1]
        );
        allDis += distance;
      }
      let playTime = 100;
      let v = allDis / playTime;
      let startTime = viewer.clock.currentTime;
      let endTime = Cesium.JulianDate.addSeconds(
          startTime,
          playTime,
          new Cesium.JulianDate()
      );
      let property = new Cesium.SampledPositionProperty();
      let t = 0;
      for (let i = 1; i < positions.length; i++) {
        if (i === 1) {
          property.addSample(startTime, positions[0]);
        }
        let dis = Cesium.Cartesian3.distance(positions[i], positions[i - 1]);
        let time = dis / v + t;
        let julianDate = Cesium.JulianDate.addSeconds(
            startTime,
            time,
            new Cesium.JulianDate()
        );
        property.addSample(julianDate, positions[i]);
        t += dis / v;
      }
      // if (carModel) {
      //   viewer.entities.remove(carModel);
      //   carModel = null;
      // }
      let modelUrl;
      let modelSize;
      if (this.radioRoad === 1) {
        modelSize = 5;
        // modelUrl="https://www.file.zouran.top/assets/cesium/glb/GroundVehicle.glb";
        modelUrl = "https://file.zouran.top/assets/cesium/glb/xiaofang.glb";
      } else if (this.radioRoad === 2) {
        modelSize = 250;
        modelUrl = "https://file.zouran.top/assets/cesium/glb/Astronaut.glb";
        // modelUrl="./models/人.glb";
      } else if (this.radioRoad === 3) {
        modelSize = 50;
        // modelUrl="https://www.file.zouran.top/assets/cesium/glb/Motorcycle.glb"
        modelUrl = "https://file.zouran.top/assets/cesium/glb/motuo.glb";
      }
      carModel = viewer.entities.add({
        position: property,
        orientation: new Cesium.VelocityOrientationProperty(property),
        model: {
          uri: modelUrl,
          scale: modelSize,
        },
      });
      viewer.clock.currentTime = startTime;
      viewer.clock.multiplier = 1;
      viewer.clock.shouldAnimate = true;
      viewer.clock.stopTime = endTime;
      // viewer.trackedEntity = entity.value.getCesiumObject()
      // viewer.zoomTo(_viewer.entities, new Cesium.HeadingPitchRange(Cesium.Math.toRadians(-90), Cesium.Math.toRadians(-15), 7500))
    },

    onHospitalReady({ cesiumObject }) {
      window.cesiumObject = cesiumObject;

      //开启聚合功能
      cesiumObject.clustering.enabled = true;
      cesiumObject.clustering.pixelRange = 30;
      cesiumObject.clustering.minimumClusterSize = 3;
    },
    onHospitalClusterEvent(clusteredEntities, cluster) {
      cluster.billboard.show = !0;
      cluster.label.show = !1;
      cluster.billboard.id = cluster.label.id;
      cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.CENTER;
      clusteredEntities.length >= 300
          ? (cluster.billboard.image =
              "https://file.zouran.top/assets/cesium/img/clusters/300+.png")
          : clusteredEntities.length >= 150
              ? (cluster.billboard.image =
                  "https://file.zouran.top/assets/cesium/img/clusters/150+.png")
              : clusteredEntities.length >= 90
                  ? (cluster.billboard.image =
                      "https://file.zouran.top/assets/cesium/img/clusters/90+.png")
                  : clusteredEntities.length >= 30
                      ? (cluster.billboard.image =
                          "https://file.zouran.top/assets/cesium/img/clusters/30+.png")
                      : clusteredEntities.length >= 10
                          ? (cluster.billboard.image =
                              "https://file.zouran.top/assets/cesium/img/clusters/10+.png")
                          : (cluster.billboard.image =
                              "https://file.zouran.top/assets/cesium/img/clusters/" +
                              clusteredEntities.length +
                              ".png");
    },
    onFireCenterReady({ cesiumObject }) {
      window.cesiumObject = cesiumObject;

      //开启聚合功能
      cesiumObject.clustering.enabled = true;
      cesiumObject.clustering.pixelRange = 30;
      cesiumObject.clustering.minimumClusterSize = 2;
    },
    onFireCenterClusterEvent(clusteredEntities, cluster) {
      cluster.billboard.show = !0;
      cluster.label.show = !1;
      cluster.billboard.id = cluster.label.id;
      cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.CENTER;
      clusteredEntities.length >= 300
          ? (cluster.billboard.image =
              "https://file.peteralbus.com/assets/cesium/img/clusters/300+.png")
          : clusteredEntities.length >= 150
              ? (cluster.billboard.image =
                  "https://file.peteralbus.com/assets/cesium/img/clusters/150+.png")
              : clusteredEntities.length >= 90
                  ? (cluster.billboard.image =
                      "https://file.peteralbus.com/assets/cesium/img/clusters/90+.png")
                  : clusteredEntities.length >= 30
                      ? (cluster.billboard.image =
                          "https://file.peteralbus.com/assets/cesium/img/clusters/30+.png")
                      : clusteredEntities.length > 10
                          ? (cluster.billboard.image =
                              "https://file.peteralbus.com/assets/cesium/img/clusters/10+.png")
                          : (cluster.billboard.image =
                              "https://file.peteralbus.com/assets/cesium/img/clusters/" +
                              clusteredEntities.length +
                              ".png");
    },
    //物资分配特效
    onMouseover(e) {
      if (e.cesiumObject instanceof Cesium.Label) {
        this.scale = 1.5; // or e.cesiumObject.scale = 1.5
        e.pickedFeature.primitive.scale = 1.5;
      } else if (e.cesiumObject instanceof Cesium.LabelCollection) {
        e.pickedFeature.primitive.scale = 1.5;
      }
    },

    onMouseout(e) {
      if (e.cesiumObject instanceof Cesium.Label) {
        this.scale = 1; // or e.cesiumObject.scale = 1
      } else if (e.cesiumObject instanceof Cesium.LabelCollection) {
        e.pickedFeature.primitive.scale = 1;
      }
    },

    //量算工具
    drawEvt(e, viewer) {
      console.log(e);
      if (e.finished) {
        this.drawing = false;
        if (e.type === "move") {
          viewer.canvas.setAttribute(
              "style",
              `cursor: ${this.restoreCursorMove}`
          );
        }
      } else {
        this.drawing = true;
        if (e.type === "move") {
          viewer.canvas.setAttribute("style", "cursor: move");
        }
        if (e.type === "new") {
          viewer.canvas.setAttribute("style", "cursor: crosshair");
        }
      }
    },
    measureEvt(e, viewer) {
      console.log(e);
      if (e.finished) {
        this.drawing = false;
        if (e.type === "move") {
          viewer.canvas.setAttribute(
              "style",
              `cursor: ${this.restoreCursorMove}`
          );
        }
      } else {
        this.drawing = true;
        if (e.type === "move") {
          viewer.canvas.setAttribute("style", "cursor: move");
        }
        if (e.type === "new") {
          viewer.canvas.setAttribute("style", "cursor: crosshair");
        }
      }
    },
    activeEvt(e, viewer) {
      console.log(e);
      viewer.canvas.setAttribute(
          "style",
          `cursor: ${e.isActive ? "crosshair" : "auto"}`
      );
      if (!e.isActive) {
        this.drawing = false;
        this.restoreCursorMove = "auto";
      }
    },
    editorEvt(e, viewer) {
      console.log(e);
      if (e.type === "move") {
        viewer.canvas.setAttribute("style", "cursor: move");
        this.drawing = true;
      }
    },
    mouseEvt(e, viewer) {
      console.log(e);
      const restoreCursor = getComputedStyle(viewer.canvas).cursor;
      if (!this.drawing) {
        if (e.type === "onmouseover") {
          this.restoreCursorMove = restoreCursor;
          viewer.canvas.setAttribute("style", "cursor: pointer");
        } else {
          viewer.canvas.setAttribute(
              "style",
              `cursor: ${this.restoreCursorMove || "auto"}`
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.toolbar {
  position: absolute;
  left: 1%;
  top: 1%;
  /*min-width: 185px;*/
  z-index: 100;
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.2);
}

.el-radio {
  margin-right: 0;
}

.el-checkbox {
  margin-right: 0;
}

.toolbar-item {
  margin: 5px;
}

.viewer {
  width: 100%;
  height: 100vh;
}

.buttonClass {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  width: 58%;
  text-align: center;
  z-index: 5;
}
</style>

<style lang="scss" scoped>
::v-deep .bg-info,
::v-deep .vc-location-other-controls .vc-distance-legend,
::v-deep .vc-location-other-controls .vc-status-bar {
  background: #00375e !important;
}

::v-deep .vc-btn--fab {
  padding: 0;
  min-height: 40px !important;
  min-width: 40px !important;
  height: 40px;
  width: 40px;
}

::v-deep .absolute-top-right {
  margin: 0 !important;
  top: 5%;
}
::v-deep .el-button--primary.is-plain {
  background: transparent;
}
</style>
